import React from 'react'
import '../../components/css/promo.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Image } from 'react-bootstrap'
import Helmet from 'react-helmet'
import DigiTindahanTagulan from '../../images/tagulan-main-promo-page.jpg'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/promos/DigiTindahanTagulan`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'DIGITINDAHAN SA TAG-ULAN PROMO (June 15 - July 31, 2023)',
  },
  {
    property: 'og:description',
    content:
      'Get free store collaterals for P500.00 initial top-up',
  },
  {
    property: 'og:image',
    content: DigiTindahanTagulan,
  },
]
const Mechanics = () => {
  return (
    <>
      <h4>MECHANICS</h4>
      <div className='mechanics lead'>
        <ul>
          <li> This promo is open to all newly-activated Digipay Agent. </li>
          <li>
            To join, a newly-activated Digipay Agent should have an initial
            top-up worth P500.00 to receive free store collaterals (1 pc
            tarpaulin, 1 pc countertop signage).
          </li>
          <li>This promo will run from June 15 to July 31, 2023 only </li>
          <li>
            Free store collaterals will be sent to newly activated agents once
            the initial top-up is confirmed.
          </li>
          <li>
            Qualified agents will be notified by Digipay via call and email
            notifications.
          </li>
          <li>
            The newly-activated Digipay Agent will receive a set of free store
            collaterals only once.
          </li>
          <li>
            Free store collaterals are non-transferable and cannot be converted
            to cash.
          </li>
        </ul>
      </div>
    </>
  )
}

const Promo5 = () => (
  <div>
    <Helmet title="Promos | DIGITINDAHAN SA TAG-ULAN PROMO" meta={SITE_META}>
      <html lang="en" />
    </Helmet>
    <Header />
    <div className="promo-page">
      <h1 align="center" className="font-weight-bold text-uppercase">
        DigiTindahan sa Tag-ulan
      </h1>
      <Image
        src={DigiTindahanTagulan}
        width="100%"
        align="center"
        className="promo-image"
      />
      <Mechanics />
    </div>
      <Footer />
  </div>
)
export default Promo5
